import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Image } from "@mui/icons-material";

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);

  // Toggle drawer state
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-between", md: "space-between" },
          alignItems: "center",
          padding: 5,
          flexDirection: { xs: "row", md: "row" },
        }}
      >
        {/* Logo */}
        <Box>
          <img
            style={{ height: "42px", width: "174.12px" }}
            src="/Logo.png"
            alt="Popcorn"
          />
        </Box>

        {/* Menu for larger screens */}
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Stack direction="row" spacing={5} alignItems="center">
            {/* Home */}
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                cursor: "pointer",
                fontWeight: "300",
                fontSize: "18px",
              }}
            >
              Home
            </Typography>

            {/* Services */}
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                cursor: "pointer",
                fontWeight: "300",
                fontSize: "18px",
              }}
            >
              Services
            </Typography>

            {/* Projects */}
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                cursor: "pointer",
                fontWeight: "300",
                fontSize: "18px",
              }}
            >
              Projects
            </Typography>

            {/* Contact */}
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                cursor: "pointer",
                fontWeight: "300",
                fontSize: "18px",
              }}
            >
              Contact
            </Typography>
          </Stack>
        </Box>

        {/* Hamburger Menu for mobile screens */}
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton onClick={handleDrawerToggle} sx={{ color: "#fff" }}>
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Drawer for mobile navigation */}
        <Drawer
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#1b1b1b", // Background color for drawer
              width: "250px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px",
            }}
          >
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <List>
            {/* Home */}
            <ListItemButton onClick={handleDrawerToggle}>
              <ListItemText
                primary="Home"
                sx={{ color: "#fff", textAlign: "center" }}
              />
            </ListItemButton>

            {/* Services */}
            <ListItemButton onClick={handleDrawerToggle}>
              <ListItemText
                primary="Services"
                sx={{ color: "#fff", textAlign: "center" }}
              />
            </ListItemButton>

            {/* Projects */}
            <ListItemButton onClick={handleDrawerToggle}>
              <ListItemText
                primary="Projects"
                sx={{ color: "#fff", textAlign: "center" }}
              />
            </ListItemButton>

            {/* Contact */}
            <ListItemButton onClick={handleDrawerToggle}>
              <ListItemText
                primary="Contact"
                sx={{ color: "#fff", textAlign: "center" }}
              />
            </ListItemButton>
          </List>
        </Drawer>
      </Box>
    </>
  );
}

export default Header;
