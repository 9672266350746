import { Box, Container, Typography } from "@mui/material";

function Hero() {
  return (
    <>
      <Box sx={{ width: "100%", position: "relative" }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "center",
              alignItems: "end",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Rock Salt, sans-serif",
                color: "#ffffff",
                fontWeight: "400",
                fontSize: { xs: "18px", md: "45px" },
                marginTop: 12,
                marginBottom: 1,
              }}
            >
              Building the
            </Typography>
            <Box
              component="img"
              src={"aero.png"}
              alt="arrow"
              sx={{
                top: { xs: "40px", md: "80px" }, // Adjust this value to position the arrow
                left: { xs: "100px", md: "240px" }, // Adjust this value to position the arrow
                width: { xs: "30px", md: "80px" }, // Adjust the size as needed
              }}
            />
          </Box>

          <Typography
            sx={{
              fontFamily: "Orbitron, sans-serif",
              color: "#ffffff",
              fontWeight: "900",
              fontSize: { xs: "65px", md: "175px" },
              margin: 0,
              padding: 0,
            }}
          >
            Future<span style={{ color: "#7CFFFC" }}>.</span>
          </Typography>
        </Container>
      </Box>

      {/* <Box sx={{ width: "75%" }}>
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: "#ffffff",
            fontWeight: "300",
            fontSize: { xs: "25px", md: "46px" },
            marginTop: 30,
          }}
        >
          Whether you already know where to place your ad or need a hand finding
          the perfect spot,
          <span style={{ color: "#7CFFFC" }}>we make it simple for you.</span>
        </Typography>
      </Box> */}
    </>
  );
}

export default Hero;
