import { Stack, Box, Typography, IconButton, Link } from "@mui/material";
import { Facebook, Twitter, LinkedIn } from "@mui/icons-material";
import { Email, Phone, LocationOn } from "@mui/icons-material";

function Footer() {
  return (
    <Box sx={{ padding: "40px 0px" }}>
      <Box
        sx={{
          backgroundColor: "#111111",
          color: "#fff",
          textAlign: "center",
          padding: "24px 0",
        }}
      >
        {/* Logo */}
        <Typography
          //   variant="h4"
          sx={{ fontSize: "40px", marginBottom: "8px" }}
        >
          Popcorn<span style={{ color: "#7CFFFC" }}>.</span>
        </Typography>

        {/* Navigation Links */}
        <Stack
          direction={{ md: "row" }}
          justifyContent="center"
          spacing={4}
          sx={{
            marginBottom: "24px",
            marginTop: "30px",
            display: { xs: "none", md: "flex" }, // Show on md and above, hide on xs
          }}
        >
          <Link
            href="#"
            underline="none"
            sx={{ color: "#fff", fontSize: "15px", fontFamily: "sans-serif" }}
          >
            Home
          </Link>
          <Link
            href="#"
            underline="none"
            sx={{ color: "#fff", fontSize: "15px", fontFamily: "sans-serif" }}
          >
            Services
          </Link>
          <Link
            href="#"
            underline="none"
            sx={{ color: "#fff", fontSize: "15px", fontFamily: "sans-serif" }}
          >
            Projects
          </Link>
          <Link
            href="#"
            underline="none"
            sx={{ color: "#fff", fontSize: "15px", fontFamily: "sans-serif" }}
          >
            Contact
          </Link>
        </Stack>

        {/* Contact Information */}
        <Box
          sx={{
            borderTop: "1px solid #444",
            borderBottom: "1px solid #444",
            padding: "16px 0",
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 0, md: 4 }}
          >
            {/* Email */}
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton sx={{ color: "#7CFFFC" }}>
                <Email />
              </IconButton>
              <Typography variant="body2">contact@popcorn.online</Typography>
            </Stack>

            {/* Phone */}
            {/* <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton sx={{ color: "#7CFFFC" }}>
                <Phone />
              </IconButton>
              <Typography variant="body2">+91 99743 45674</Typography>
            </Stack> */}

            {/* Location */}
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton sx={{ color: "#7CFFFC" }}>
                <LocationOn />
              </IconButton>
              <Typography variant="body2">Dubai</Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#1b1b1b",
          color: "#000",
          padding: "16px 32px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "40px",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 2 },
        }}
      >
        {/* Left Side - Social Media Icons */}
        <Stack direction="row" spacing={2}>
          <IconButton
            component="a"
            // href="https://facebook.com"
            target="_blank"
            sx={{
              color: "#000",
              backgroundColor: "#7CFFFC",
              borderRadius: "50%",
            }}
          >
            <Facebook />
          </IconButton>
          <IconButton
            component="a"
            // href="https://twitter.com"
            target="_blank"
            sx={{
              color: "#000",
              backgroundColor: "#7CFFFC",
              borderRadius: "50%",
            }}
          >
            <Twitter />
          </IconButton>
          <IconButton
            component="a"
            // href="https://linkedin.com"
            target="_blank"
            sx={{
              color: "#000",
              backgroundColor: "#7CFFFC",
              borderRadius: "50%",
            }}
          >
            <LinkedIn />
          </IconButton>
        </Stack>

        {/* Center - Text */}
        <Typography
          variant="body2"
          sx={{ fontSize: "14px", textAlign: "center", color: "#fff" }}
        >
          @Popcorn All Rights Reserved
        </Typography>

        {/* Right Side - Links */}
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Link
            href="/privacy-policy"
            underline="hover"
            sx={{ color: "#fff", fontFamily: "sans-serif" }}
          >
            Privacy Policy & Terms of Service
          </Link>
        </Stack>
      </Box>
    </Box>
  );
}

export default Footer;
