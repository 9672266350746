import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";

function Core() {
  return (
    <Box sx={{ width: "100%", marginTop: "0px" }}>
      <Container maxWidth="xl">
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#ffffff",
              fontWeight: "500",
              fontSize: { xs: "20px", md: "40px" },
              marginTop: { xs: 5, md: 15 },
            }}
          >
            Get Your Ad on Billboards in{" "}
            <span style={{ color: "#7CFFFC" }}>Easy Steps</span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#ffffff",
              fontWeight: "300",
              fontSize: { xs: "15px", md: "24px" },
              // letterSpacing: "2px",
              marginTop: 5,
              width: "65%",
            }}
          >
            See how effortless it is to get your message on the big screen with
            just a few clicks!
          </Typography>
        </Box>
        <Box
          sx={{
            // backgroundColor: "#111111",
            padding: "40px 0",
            color: "#fff",
          }}
        >
          {/* Single Grid containing all Cards */}
          <Grid container spacing={4} justifyContent="center">
            {/* Card 1 */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#1b1b1b",
                  padding: "16px",
                  borderRadius: "12px",
                  color: "#fff", // Set font color to white
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "#ffffff",
                      letterSpacing: "1px",
                      fontWeight: "200",
                      marginBottom: "8px",
                    }}
                  >
                    1. Choose Your Billboard
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "#ffffff",
                      fontWeight: "100",
                      letterSpacing: "2px",
                    }}
                  >
                    Select your desired billboard location or let us suggest one
                    based on your audience and goals.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Card 2 */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#1b1b1b",
                  padding: "16px",
                  borderRadius: "12px",
                  color: "#fff", // Set font color to white
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "#ffffff",
                      letterSpacing: "1px",
                      fontWeight: "200",
                      marginBottom: "8px",
                    }}
                  >
                    2. Set Your Budget & Dates
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "#ffffff",
                      fontWeight: "100",
                      letterSpacing: "2px",
                    }}
                  >
                    Pick the duration of your campaign and your budget range.
                    We’ll ensure you get the best value for your investment.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Card 3 */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#1b1b1b",
                  padding: "16px",
                  borderRadius: "12px",
                  color: "#fff", // Set font color to white
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "#ffffff",
                      letterSpacing: "1px",
                      fontWeight: "200",
                      marginBottom: "8px",
                    }}
                  >
                    3. Customize Your Ad Space
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "#ffffff",
                      fontWeight: "100",
                      letterSpacing: "2px",
                    }}
                  >
                    Upload your ad creative or work with our team to design the
                    perfect campaign that fits your message and space.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Card 4 */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#1b1b1b",
                  padding: "16px",
                  borderRadius: "12px",
                  color: "#fff", // Set font color to white
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "#ffffff",
                      letterSpacing: "1px",
                      fontWeight: "200",
                      marginBottom: "8px",
                    }}
                  >
                    4. Launch and Track Performance
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "#ffffff",
                      fontWeight: "100",
                      letterSpacing: "2px",
                    }}
                  >
                    Go live and watch your ad make an impact! Track performance
                    metrics in real time to see how your campaign is performing.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* CTA Section */}
      <Container maxWidth="xl">
        <Box
          sx={{
            // backgroundColor: "#111111",
            padding: "40px 0",
            color: "#fff",
          }}
        >
          <Grid container spacing={4} justifyContent="center">
            <Grid item sm={12}>
              <Card
                sx={{
                  backgroundColor: "#1b1b1b",
                  padding: "16px",
                  borderRadius: "12px",
                  color: "#fff", // Set font color to white
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <Box sx={{ width: { md: "80%" } }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "400",
                          marginBottom: "8px",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "32px",
                          color: "#ffffff",
                        }}
                      >
                        Get your brand in the spotlight with Popcorn{" "}
                        <span style={{ color: "#7CFFFC" }}>today!</span>
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          marginBottom: "16px",
                          fontFamily: "Poppins, sans-serif",
                          color: "#ffffff",
                          fontWeight: "100",
                          letterSpacing: "2px",
                          width: { xs: "100%", md: "75%" },
                          // letterSpacing: "1px",
                        }}
                      >
                        Ready to make a bold statement? Join Popcorn now, and
                        let us help you launch your campaign with ease on
                        billboards across prime locations.
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#7CFFFC",
                        color: "#000000",
                        borderRadius: "50px",
                        padding: "8px 24px",
                        height: "50.11px",
                        width: { xs: "100%", md: "160px" },
                        textTransform: "none",
                      }}
                    >
                      Place Your Ad
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {/* <Card
            sx={{
              backgroundColor: "#1b1b1b",
              padding: "24px",
              borderRadius: "12px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: "8px" }}
              >
                Get your brand in the spotlight with Popcorn{" "}
                <span style={{ color: "#E74C3C" }}>today!</span>
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "16px" }}>
                Ready to make a bold statement? Join Popcorn now, and let us
                help you launch your campaign with ease on billboards across
                prime locations.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#E74C3C",
                  borderRadius: "20px",
                  padding: "8px 24px",
                }}
              >
                Place Your Ad
              </Button>
            </CardContent>
          </Card> */}
      </Container>
    </Box>
  );
}

export default Core;
