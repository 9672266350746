import { Box, Container } from "@mui/material";
import Header from "../components/Header";
import "../pages/home.css";
import Hero from "../components/Hero";
import Core from "../components/Core";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <Box>
        <Box className="background-image-container">
          {/* Background Image */}
          <Box className="background-image" />
          <Container maxWidth={"xl"}>
            <Header />
            <Hero />
          </Container>
        </Box>

        <Container maxWidth={"xl"}>
          <Core />
          <Footer />
        </Container>
      </Box>
    </>
  );
}

export default Home;
